.table {
  width: 100%;
  display: grid;
}

.table tr,
.table thead {
  display: grid;
  grid-template-columns: 180px 180px repeat(auto-fit, minmax(50px, 1fr));
}

.table .threeSlot {
  display: grid;
  grid-template-columns: repeat(3, 30%);
}

.table .oneSlot {
  display: block;
}

.table * {
  text-align: center;
}

.tdGroup {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.table .tdGroup,
.tdGroup td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table .tdGroup,
.tdGroup table {
  height: 100%;
  width: 100%;
}

.cell {
  padding: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.nowrap {
  white-space: nowrap;
}

/* .dateCell { */
/*   width: 17%; */
/* } */
