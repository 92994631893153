.loginForm {
  width: 100%;
  max-width: 400px;
  padding: 0 5px;
}

.poweredBy {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.passWrapper {
  position: relative;
}

.vis {
  opacity: 0.5;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.vis.active {
  opacity: 1;
}
